<template>
  <div>
    <h4>GRUPOS</h4>

    <button
      class="btn btn-primary mb-2"
      @click="
        $refs.dForm.show();
        $refs.form.reset();
      "
    >
      <i class="fa fa-plus"></i> Nuevo
    </button>

    <div class="table-responsive">
      <table class="table table-sm table-bordered bg-white">
        <tbody>
          <tr v-for="(l, k) in list" :key="k">
            <td>{{ l.categorie_name }}</td>
            <td>
              <ul class="m-0 list-group">
                <li
                  class="list-group-item p-0 pb-1 border-0"
                  v-for="(l1, k1) in l.list"
                  :key="k1"
                >
                  <span>{{ l1.name }}</span>
                  <a
                    href="#"
                    @click.prevent="
                      $refs.dPreDel.val = l1.id;
                      $refs.dPreDel.show();
                    "
                    class="ml-2"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-modal-basic ref="dForm">
      <form-group
        @submitted="
          $refs.dForm.hide();
          getList();
        "
        ref="form"
      ></form-group>
    </app-modal-basic>

    <app-modal-yn
      ref="dPreDel"
      @yes="
        deleteItem($refs.dPreDel.val);
        $refs.dPreDel.hide();
      "
    >
      <p>Eliminar del grupo</p>
    </app-modal-yn>
  </div>
</template>
<script>
import { AdminService } from "../AdminService";
import FormGroup from "./FormGroup";

export default {
  components: {
    FormGroup
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      AdminService.getSourceGroups().then(res => {
        this.list = res;
      });
    },
    deleteItem(id) {
      AdminService.deleteSourceGroup(id).then(() => {
        this.getList();
      });
    }
  }
};
</script>

<style></style>

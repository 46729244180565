<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Area*</label>
      <!-- <AreaPicker required :area-id.sync="reg.area_id"></AreaPicker> -->
      <SelectArea :required="true" v-model="reg.area_id"></SelectArea>
    </div>
    <div class="form-group">
      <label for>Nombre*</label>
      <input type="text" class="form-control" v-model="reg.name" required />
    </div>
    <div class="form-group" v-if="!reg.id">
      <label for>Meta</label>
      <app-input-money
        ref="inputMoney"
        :quantity.sync="reg.budget"
        :moneyCode.sync="reg.money_code"
      ></app-input-money>
      <!-- <ltg-money-input :quantity.sync="reg.budget" :moneyCode.sync="reg.money_code"></ltg-money-input> -->
    </div>
    <div class="form-group">
      <label for>Descripción</label>
      <textarea
        rows="3"
        class="form-control"
        v-model="reg.description"
      ></textarea>
    </div>
    <div class="form-group">
      <button :disabled="sending" class="btn btn-primary" type="submit">
        Guardar
      </button>
    </div>
    <!-- <div class="form-group">
      <ltg-alert class="alert-success" msj="Creado con exito" :show.sync="saveSuccess"></ltg-alert>
      <ltg-alert class="alert-danger" :msj="err" :show.sync="saveError">
        <template slot="msj">
          <strong>Error!</strong> {{ err }}
        </template>
      </ltg-alert>
    </div>-->
  </form>
</template>

<script>
import SelectArea from "../areas/Select";
import { AdminService } from "../AdminService";

export default {
  components: { SelectArea },
  data() {
    return {
      reg: {},
      sending: false
    };
  },
  methods: {
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
    },
    reset() {
      this.reg = {};
      this.reg.area_id = this.$store.state.auth.area_id;
      this.$refs.inputMoney.setDefault();
      this.sending = false;
    },
    save() {
      AdminService.saveSource(this.reg).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>

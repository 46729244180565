<template>
  <div>
    <div class="form-group">
      <label for="">Grupo</label>
      <select-categorie
        ref="selcat"
        v-model="categorie_name"
      ></select-categorie>
    </div>
    <div class="form-group">
      <label for="">Fuente de Ingresos</label>
      <SelectSource ref="selSou" v-model="source_id"></SelectSource>
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import SelectCategorie from "../../general-module/categories/InputSelect";
import SelectSource from "../sources/Select";
import { AdminService } from "../AdminService";

export default {
  components: {
    SelectCategorie,
    SelectSource
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    categorie_name: null,
    source_id: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.categorie_name = null;
      this.source_id = null;
      this.$refs.selcat.reset();
      this.$refs.selSou.reset();
    },
    save() {
      AdminService.saveSourceGroup(this.$data).then(res => {
        this.$emit("submitted", res);
      });
    }
  }
};
</script>

<style></style>
